<template>
  <section class="about-us">
    <h2 class="font-bold">
      <slot name="header"></slot>
    </h2>
    <p class="font-reqular description">
      <slot name="body"></slot>
    </p>
    <div class="text-center">
      <b-btn
        @click="overlayToggle"
        variant="default"
        class="router-link mt-5 mx-auto"
        ><span>{{ $t("button.register") }}</span>
      </b-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: "MoreContent",
  methods: {
    overlayToggle() {
      this.$store.dispatch("SET_OVERLAY");
    }
  }
};
</script>
